#brands-view {
  @extend %section-width;
  @extend %section-destop-vertical-space-bottom;
  @extend %centered-desktop;

  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-top: $gutter-space-base;
    padding-bottom: $gutter-space-base;
  }

  h2 {
    @media(max-width: ($layout-breakpoint-medium)-1) { margin-bottom: $gutter-space-small; }
    @media(min-width: $layout-breakpoint-medium) { margin-bottom: $gutter-space-base; }
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($gutter-space-small);
    @media(max-width: ($layout-breakpoint-medium)-1) { margin-left: -($gutter-space-small); }
    @media(min-width: $layout-breakpoint-medium) { margin-left: -($gutter-space-base); }

    li {
      flex-grow: 0;
      flex-shrink: 0;
      @media(max-width: ($layout-breakpoint-medium)-1) { margin-top: $gutter-space-small; }
      @media(max-width: ($layout-breakpoint-tiny)-1) { flex-basis: 50%; }
      @media(min-width: $layout-breakpoint-tiny) and (max-width: ($layout-breakpoint-medium)-1) { flex-basis: 33.3333%; }
      @media(min-width: $layout-breakpoint-medium) { flex-basis: 16.6666%; }
    }

    a {
      display: flex;
      justify-content: center;
      position: relative;
      min-height: 100%;
      align-items: center;
      line-height: rem-calc(50);
      text-align: center;
      @media(max-width: ($layout-breakpoint-medium)-1) { margin-left: $gutter-space-small; }
      @media(min-width: $layout-breakpoint-medium) { margin-left: $gutter-space-base; }
    }
      img {
        display: block;
        flex: 0 1 auto;
        max-width: 100%;
        max-height: rem-calc(50);
      }
  }

  .btn {
    @media(max-width: ($layout-breakpoint-tiny)-1) { display: block; }
    @media(max-width: ($layout-breakpoint-medium)-1) { margin-top: $gutter-space-small; }
    @media(min-width: $layout-breakpoint-medium) { margin-top: $gutter-space-base; }
  }
}
  .content-widget + #brands-view {
    @media(max-width: ($layout-breakpoint-medium)-1) { padding-top: 0; }
  }