.opening-times {
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: $content-space-small;
  margin: 0;

  dt { white-space: nowrap; }
  dd { margin-left: 0; }
}

.contact-details {
  a:not(.page-link) {
    color: $text-color-base;

    &:hover { color: $primary-color; }
  }
  > a { margin-bottom: $content-space-tiny; }

  [class^='icon-'],
  [class*=' icon-'] {
    display: inline-block;
    position: relative;
    padding-left: (($font-size-base*$line-height-small)+($content-space-small));

    &:before {
      position: absolute;
      top: 0; left: 0;
      color: $primary-color;
      display: inline-block;
      width: ($font-size-base*$line-height-base); height: ($font-size-base*$line-height-base); line-height: ($font-size-base*$line-height-base);
      text-align: center;
    }
  }
    .icon-mail:before     { font-size: $icon-size-medium; }
    .icon-phone:before    { font-size: $icon-size-large; }
    .icon-address:before  { font-size: $font-size-small; }
}