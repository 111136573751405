@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?j1k216');
  src: url('../fonts/icomoon.eot?j1k216#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?j1k216') format('truetype'),
  url('../fonts/icomoon.woff?j1k216') format('woff'),
  url('../fonts/icomoon.svg?j1k216#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font {
  font-family: 'icomoon';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
@mixin indicator-icon {
  @include icon-font;
  font-size: $icon-size-base;
  position: relative;
  top: -(rem-calc(1));
}
@mixin indicator-icon-before {
  @include indicator-icon;
  margin-right: $content-space-tiny;
}
@mixin indicator-icon-after {
  @include indicator-icon;
  margin-left: $content-space-tiny;
}

[class^='icon-']:before,
[class*=' icon-']:before,
[class^='icon-']:after,
[class*=' icon-']:after {
  @include icon-font;
}

.icon-facebook:before   { content: "\e905"; }
.icon-instagram:before  { content: "\e906"; }
.icon-linkedin:before   { content: "\e907"; }
.icon-mail:before       { content: "\e908"; }
.icon-phone:before      { content: "\e909"; }
.icon-address:before    { content: "\e90a"; }
.icon-whatsapp:before   { content: "\e90b"; }
