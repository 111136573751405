#top {
  @extend %section-desktop-width;

  @media(min-width: $layout-breakpoint-medium) {
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    z-index: 1337;
    background: $bg-color-base;
    display: flex;
    align-items: center;
    border-bottom: $border-width-base solid $bg-color-light;

    &.state-show-sub-menu:after {
      content: '';
      position: fixed;
      top: ((rem-calc(40))+($gutter-space-base*2));
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      background: transparentize($bg-color-dark, .85);
      @include fadeIn($transition-speed);
    }
  }

  .pre-top {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      background: $bg-color-base;
      position: fixed;
      top: 0; left: 0;
      width: 100%;
      z-index: 1337;
      display: flex;
      align-items: center;
      padding: $gutter-space-small;
      border-bottom: $border-width-base solid $border-color-base;
    }
    @media(min-width: $layout-breakpoint-medium) {
      flex: 0 0 auto;
      margin-right: $gutter-space-base;
    }
  }
    .logo {
      display: block;

      @media(max-width: ($layout-breakpoint-medium)-1) { flex: 0 0 auto; }

      img {
        display: block;
        height: rem-calc(40);
      }
    }
    #trigger-main-navigation {
      @media(max-width: ($layout-breakpoint-medium)-1) {
        flex: 0 0 auto;
        margin-left: auto;
        background: none;
        border: none;
        padding: 0;
        display: flex;
        align-items: center;

        .icon-cont {
          flex: 0 0 auto;
          display: inline-block;
          width: rem-calc(25);
          height: ($border-width-base*14);
          vertical-align: middle;
          margin-right: $gutter-space-tiny;

          .icon {
            margin-top: ($border-width-base*6);
            display: block;
            position: relative;
            height: ($border-width-base*2);

            .base-line, &:after, &:before {
              display: block;
              background: $text-color-base;
              width: 100%; height: 100%;
            }
              .base-line {transition: background-color $transition-speed;}
              &:after, &:before {
                content: '';
                position: absolute;
                left: 0;
                transition: transform .15s ease-out;
              }
                &:before  {top: -($border-width-base*6);}
                &:after   {top: ($border-width-base*6);}
          }
        }
        .anchor { flex: 0 0 auto; }
      }
      @media(min-width: $layout-breakpoint-medium) { display: none; }
    }
}

#main-navigation {
  @media(max-width: ($layout-breakpoint-medium)-1) {
    display: none;
    background: $bg-color-base;
  }
  @media(min-width: $layout-breakpoint-medium) {
    flex: 0 1 auto;
    margin-left: auto;
  }
}

#main-menu {
  @media(min-width: $layout-breakpoint-medium) {
    display: flex;
    align-items: center;
  }

  a {
    display: block;
    color: $text-color-dark;

    &:hover { color: $primary-color; }
  }
    .state-current > a { color: $primary-color; }

  .state-has-sub-menu > a {
    display: flex;
    align-items: center;

    &:after {
      @include indicator-icon-after;
      content: "\e904";
      color: $primary-color;
      position: relative;
      top: $border-width-base;
      margin-left: auto;

      @media(min-width: $layout-breakpoint-medium) { margin-left: $gutter-space-tiny; }
    }
  }

  .sub-menu { display: none; }

  .state-show-sub-menu > {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      a:after { content: "\e902"; }
      .sub-menu {
        display: block;
        @include fadeIn($transition-speed);
      }
    }
  }

  > li {
    @media(min-width: $layout-breakpoint-medium) {
      flex: 0 0 auto;

      &:not(:last-child)  { margin-right: $gutter-space-base; }
    }

    > a, &.product-lists > .sub-menu > li > a {
      @media(max-width: ($layout-breakpoint-medium)-1) { padding: $gutter-space-small; }
    }

    &:not(:last-child) {
      @media(max-width: ($layout-breakpoint-medium)-1) { border-bottom: $border-width-base solid $border-color-base; }
    }

    &.corporate-link {
      @media(max-width: ($layout-breakpoint-medium)-1) { font-weight: $font-weight-bold; }
      @media(min-width: $layout-breakpoint-medium) {
        > a {
          border-left: $border-width-base solid $border-color-base;
          line-height: $line-height-small;
          padding-left: $gutter-space-base;
        }
      }
    }

    &.product-lists > .sub-menu {
      @media(max-width: ($layout-breakpoint-medium)-1) { background: $bg-color-light; }

      > li {
        @media(max-width: ($layout-breakpoint-medium)-1) { border-top: $border-width-base solid darken($bg-color-light, 10%); }

        &.state-has-sub-menu > a:after {
          @media(min-width: $layout-breakpoint-medium) { display: none; }
        }
      }
    }
    &.product-lists .sub-menu .sub-menu, &:not(.product-lists) .sub-menu {
      font-size: $font-size-small;

      @media(max-width: ($layout-breakpoint-medium)-1) { padding: 0 ($gutter-space-small) ($gutter-space-small) ($gutter-space-small); }

      > li:not(:last-child) { margin-bottom: $gutter-space-tiny; }
    }

    @media(min-width: $layout-breakpoint-medium) {
      padding-top: $gutter-space-base;
      padding-bottom: $gutter-space-base;

      > a { line-height: rem-calc(40); }
        &.state-has-sub-menu:hover > a { color: $primary-color; }

      &.state-has-sub-menu {
        > .sub-menu {
          border-top: $border-width-base solid $border-color-light;
          background: $bg-color-base;

          a { white-space: nowrap; }
        }
      }

      &.state-has-sub-menu:not(.product-lists) {
        position: relative;

        > .sub-menu {
          position: absolute;
          top: 100%; left: -($gutter-space-base);
          padding: $gutter-space-base;
        }
          &:hover > .sub-menu {
            display: block;
            @include fadeInDownLight($transition-speed);
          }
      }
    }
    &.product-lists {
      > .sub-menu {
        @extend %section-desktop-width;

        @media(min-width: $layout-breakpoint-medium) {
          padding-top: ($gutter-space-base*2);
          padding-bottom: ($gutter-space-base*2);
          position: absolute;
          top: ((rem-calc(40))+($gutter-space-base*2));
          left: 0;
          align-items: flex-start;
          justify-content: center;
          width: 100%;

          > li {
            flex: 0 0 auto;

            &:not(:last-child) { margin-right: ($gutter-space-base*2); }
          }

          .sub-menu {
            display: block;
            margin-top: $gutter-space-small;
          }
        }
      }
      @media(min-width: $layout-breakpoint-medium) {
        &:hover > .sub-menu {
          display: flex;
          @include fadeInDownLight($transition-speed);
        }
      }
    }
  }
}

#notice-bar {
  padding: $gutter-space-small;
  color: $secondary-color;
  font-size: $font-size-small;
  text-align: center;
  background: $bg-color-light;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
  a#notice-bar {display: block;}

body {
  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-top: ((rem-calc(40))+($gutter-space-small*2));
  }
  @media(min-width: $layout-breakpoint-medium) {
    padding-top: ((rem-calc(40))+($gutter-space-base*2));
  }

  &.state-main-menu-open {
    @media(max-width: ($layout-breakpoint-medium)-1) { overflow: hidden; }

    #top {
      @media(max-width: ($layout-breakpoint-medium)-1) {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;

        #trigger-main-navigation .icon-cont .icon {
          .base-line {background: transparent;}
          &:before {
            top: -50%;
            transform: translateY(50%) rotate(45deg);
          }
          &:after {
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
    #main-navigation {
      @media(max-width: ($layout-breakpoint-medium)-1) {
        position: fixed;
        top: 0; left: 0;
        z-index: 1327;
        width: 100%; height: 100%;
        padding-top: ((rem-calc(40))+($gutter-space-small*2));
        overflow-y: scroll;
        overflow-x: hidden;
        display: block;
        @include fadeIn($transition-speed);
      }
    }
  }
}