.collapsible-content {

  .extra-content { display: none; }

  .trigger-collapsible-content {
    @extend %trigger-link;
    background: none;
    border: none;
    padding: 0;
    cursor: s-resize;

    &:after {
      @include indicator-icon-after;
      content: "\e904";
      opacity: .5;
      transition: opacity $transition-speed;
    }
      &:hover:after { opacity: 1; }
  }

  &.state-show-content {
    .trigger-collapsible-content {
      cursor: n-resize;

      &:after { content: "\e903"; }
    }
      > .trigger-collapsible-content { display: none; }

    .extra-content {
      display: block;
      @include fadeIn($transition-speed);
    }
  }
}
