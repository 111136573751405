body > .cta-bar {
  @extend %section-desktop-width;
  @extend %section-destop-vertical-space;
  @extend %centered-desktop;

  .inner-wrap { @extend %widget-defaults; }
}
  body > .content-widget + .cta-bar, #basic-section + .cta-bar, #content-section + .cta-bar {
    @media(min-width: $layout-breakpoint-medium) { padding-top: 0; }
  }

main > .cta-bar {
  @media(min-width: $layout-breakpoint-medium) {
    border-top: $border-width-base solid $bg-color-light;
    padding-top: ($gutter-space-base*3);
    margin-top: ($gutter-space-base*3);
  }

  .inner-wrap { @extend %widget-defaults; }
}
  main .overview-content + .cta-bar {
    @media(min-width: $layout-breakpoint-medium) {
      border-top: none;
      padding-top: 0;
    }
  }

body > .block-nav:not(.state-dark) + .cta-bar, #brands-overview + .cta-bar {
  @media(min-width: $layout-breakpoint-medium) { padding-top: 0; }
}