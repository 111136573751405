%widget-defaults {
  background: $bg-color-light;

  @media(max-width: ($layout-breakpoint-medium)-1) { padding: $gutter-space-base $gutter-space-small; }
  @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { padding: $gutter-space-base; }

  p:not([class]) { margin-bottom: $content-space-sidebar; }

  .btn:not(.state-primary) { border-color: transparent; }

  > *:last-child {margin-bottom: 0;}
}
body > .cta-bar %widget-defaults, main > .cta-bar .inner-wrap {
  @media(min-width: $layout-breakpoint-large) { padding: ($gutter-space-base*2); }
}
.aligned-blocks .block-widget {
  @media(min-width: $layout-breakpoint-large) { padding: $gutter-space-base; }
}
aside %widget-defaults {
  @media(min-width: $layout-breakpoint-large) { padding: ($gutter-space-small*2); }
}
main > %widget-defaults {
  @media(min-width: $layout-breakpoint-large) { padding: ($gutter-space-small*2); }
}

%centered-desktop {
  @media(min-width: $layout-breakpoint-medium) {
    text-align: center;

    .btn-cont {
      justify-content: center;

      .link-btn-cont .link-btn { text-align: left; }
    }
  }
}