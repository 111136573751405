@function rem-calc($size) {
  @return ($size/16)*1rem;
}

$font-family-base:        'Inter', sans-serif;
$font-family-headings:    'helvetica_neueregular', sans-serif;

$font-size-small:         rem-calc(14);
$font-size-base:          rem-calc(16);
$font-size-medium:        rem-calc(18);

$heading-size-tiny:       $font-size-medium;
$heading-size-small:      rem-calc(21);
$heading-size-between:    rem-calc(27);
$heading-size-medium:     rem-calc(37);
$heading-size-large:      rem-calc(43);

$icon-size-base:          rem-calc(8);
$icon-size-medium:        rem-calc(10);
$icon-size-large:         rem-calc(12);

$font-weight-normal:      400;
$font-weight-bold:        700;
$font-weight-base:        $font-weight-normal;

$line-height-small:       1.15;
$line-height-base:        1.5;

$text-color-base:         #484848;
$text-color-dark:         #121212;
$text-color-medium:       transparentize($text-color-dark, .35);
$text-color-light:        transparentize($text-color-dark, .50);

$text-color-white:        #FFF;
$text-color-white-medium: transparentize($text-color-white, .25);

$transition-speed:        .15s;

$primary-color:           #F89826;
$primary-hover-color:     darken($primary-color, 10%);

$secondary-color:         #21417C;
$secondary-hover-color:   lighten($secondary-color, 15%);

$negative-color:          #C66767;
$negative-bg-color:       transparentize($negative-color, .85);

$bg-color-base:           #FFF;
$bg-color-light:          #EAEDF3;
$bg-color-dark:           $text-color-dark;

$border-radius-base:      rem-calc(5);

$border-width-base:       rem-calc(1);
$border-color-base:       #D3DEDC;
$border-color-light:      $bg-color-light;
$border-color-dark:       $bg-color-dark;

$gutter-space-tiny:       rem-calc(5);
$gutter-space-small:      rem-calc(15);
$gutter-space-base:       rem-calc(25);

$content-space-tiny:      $gutter-space-tiny;
$content-space-small:     rem-calc(10);
$content-space-medium:    $gutter-space-small;
$content-space-base:      $gutter-space-base;
$content-space-sidebar:   rem-calc(20);

$layout-breakpoint-tiny:        550px;
$layout-breakpoint-small:       750px;
$layout-breakpoint-medium:      1000px;
$layout-breakpoint-large:       1200px;
$layout-breakpoint-extra-large: 1400px;

@mixin aspect-ratio-container($width, $height) {
  padding-bottom: (($height / $width) * 100%);
}
%aspect-ratio-image {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
}