.list-actions {
  list-style: none;
  padding: 0; margin: 0;

  li:not(:last-child) { margin-bottom: $border-width-base; }

  a {
    display: flex;
    align-items: center;
    overflow: hidden;
    background: $bg-color-base;
    padding: .25em 1em .25em .25em;
    color: $text-color-base;

    &:hover { color: $primary-color; }

    img {
      flex: 0 0 auto;
      display: block;
      max-height: rem-calc(20);
      margin-right: $content-space-tiny;
    }

    span {
      flex: 0 1 auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
  main > .list-actions {
    border-bottom: $border-width-base solid $border-color-light;

    a {
      padding: ($gutter-space-small) 0;
      border-top: $border-width-base solid $border-color-light;
    }
  }