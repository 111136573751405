.aligned-blocks {
  @extend %section-desktop-width;
  @extend %section-destop-vertical-space-bottom;

  @media(min-width: $layout-breakpoint-medium) { display: flex; }
  @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { margin-left: -($gutter-space-base); }
  @media(min-width: $layout-breakpoint-large) { margin-left: -($gutter-space-base*2); }

  .el {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      &:not(:last-child) { margin-bottom: $gutter-space-base; }
    }
    @media(min-width: $layout-breakpoint-medium) {
      flex: 0 0 50%;
      min-width: 0;

      &:only-child { flex-grow: 1; }
    }
  }

  .block-widget {
    @media(min-width: $layout-breakpoint-medium) {
      position: relative;
      min-height: 100%;
    }
    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { margin-left: $gutter-space-base; }
    @media(min-width: $layout-breakpoint-large) { margin-left: ($gutter-space-base*2); }
  }
}
  .block-nav.state-dark + .aligned-blocks {
    @media(max-width: ($layout-breakpoint-medium)-1) { padding-top: $gutter-space-base; }
    @media(min-width: $layout-breakpoint-medium) { padding-top: ($gutter-space-base*3); }
  }