#brands-overview {
  @extend %section-base;

  .nav {
    display: flex;
    flex-wrap: wrap;

    @media(max-width: ($layout-breakpoint-medium)-1) {
      margin-left: -($gutter-space-small);
      margin-top: -($gutter-space-small);
    }
    @media(min-width: $layout-breakpoint-medium) {
      margin-left: -($gutter-space-base);
      margin-top: -($gutter-space-base);
    }

    li {
      flex-grow: 0;
      flex-shrink: 0;
      @media(max-width: ($layout-breakpoint-medium)-1) { margin-top: $gutter-space-small; }
      @media(max-width: 399px) { flex-basis: 50%; }
      @media(min-width: 400px) and (max-width: ($layout-breakpoint-tiny)-1)  { flex-basis: 33.3333%; }
      @media(min-width: $layout-breakpoint-tiny) and (max-width: ($layout-breakpoint-small)-1) { flex-basis: 25%; }
      @media(min-width: $layout-breakpoint-small) and (max-width: ($layout-breakpoint-medium)-1) { flex-basis: 20%; }
      @media(min-width: $layout-breakpoint-medium) {
        flex-basis: 16.6666%;
        margin-top: $gutter-space-base;
      }
    }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        min-height: 100%;
        border: $border-width-base solid $border-color-light;
        line-height: rem-calc(50);
        border-radius: $border-radius-base;
        transition: border-color $transition-speed;

        &:hover { border-color: $border-color-base; }

        @media(max-width: ($layout-breakpoint-medium)-1)  {
          padding: ($gutter-space-base) ($gutter-space-small);
          margin-left: $gutter-space-small;
        }
        @media(min-width: $layout-breakpoint-medium)  {
          padding: ($gutter-space-base*2) ($gutter-space-base);
          margin-left: $gutter-space-base;
        }
      }
        img {
          display: block;
          flex: 0 1 auto;
          max-width: 100%;
          max-height: rem-calc(50);
        }
  }
}