#banner {
  color: $text-color-white;
  background: $primary-color;
  position: relative;

  %heading-base { color: $text-color-white; }

  a {
    color: $text-color-white-medium;

    &:hover { color: $text-color-white; }
  }

  ul {
    list-style: none;
    padding: 0; margin: 0;

    li { position: relative; }
  }

  .outer {
    @extend %section-desktop-width;

    @media(min-width: $layout-breakpoint-medium) {
      position: absolute;
      bottom: $gutter-space-base; left: 0;
      width: 100%;
      overflow: hidden;
    }
  }

  .content {
    @media(max-width: ($layout-breakpoint-medium)-1) { padding: ($gutter-space-base) ($gutter-space-small); }
    @media(min-width: $layout-breakpoint-medium) {
      padding: ($gutter-space-base) ($gutter-space-base) (($gutter-space-base*2)+(rem-calc(5)));
      border-radius: ($border-radius-base*2);
      background: $primary-color;
    }

    > *:last-child { margin-bottom: 0; }
  }

  .content, .glide__bullets {
    @media(min-width: $layout-breakpoint-medium) {
      width: rem-calc(400);
      float: right;
    }
  }


  img {
    display: block;
    width: 100%;
  }

  .controls {
    @extend %section-desktop-width;
    overflow: hidden;

    @media(max-width: ($layout-breakpoint-medium)-1) { padding: 0 ($gutter-space-small) ($gutter-space-base); }
    @media(min-width: $layout-breakpoint-medium) {
      position: absolute;
      bottom: ($gutter-space-base*2); left: 0;
      width: 100%;
    }

    .glide__bullets .glide__bullet { background: $bg-color-base; }
  }
}