@import "glide-variables";

.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  position: relative;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  &#{$se}track {
    overflow: hidden;
  }

  &#{$se}slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;

    &#{$glide-modifier-separator}dragging {
      user-select: none;
    }
  }

  &#{$se}slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  &#{$se}arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$se}bullets {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$sm}rtl {
    direction: rtl;
  }
}

.glide__bullets {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  line-height: rem-calc(5);

  .glide__bullet {
    background: none;
    border: none;
    padding: 0;
    width: rem-calc(5); height: rem-calc(5);
    border-radius: 50%;
    opacity: .25;
    background: $bg-color-dark;
    transition: opacity $transition-speed;

    &:focus { outline: none; }

    &.glide__bullet--active {opacity: 1;}

    &:not(:last-child) {margin-right: $gutter-space-tiny;}
  }
}