$block-widget-img-width: 175;

.block-widget {
  @extend %widget-defaults;

  &.state-bg-img {
    @media(min-width: $layout-breakpoint-tiny) { position: relative; }
    @media(min-width: $layout-breakpoint-tiny) and (max-width: ($layout-breakpoint-medium)-1) { padding-left: ((rem-calc($block-widget-img-width))+($gutter-space-small)); }
    @media(min-width: $layout-breakpoint-medium) { padding-left: ((rem-calc($block-widget-img-width))+($gutter-space-base)); }

    .bg {
      @media(min-width: $layout-breakpoint-tiny) {
        position: absolute;
        top: 0; left: 0;
        z-index: 2;
        width: rem-calc($block-widget-img-width);
        height: 100%;
        background-color: $border-color-base;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }

  .offer-brand-link {
    display: block;
    background: $bg-color-base;
    padding: $gutter-space-base;
    text-align: center;

    &:not(:last-child) { margin-bottom: $gutter-space-base; }

    img {
      display: inline-block;
      max-width: 100%;
    }
  }
}

.clear-widget {
  @media(max-width: ($layout-breakpoint-medium)-1) { padding: 0 $gutter-space-small; }

  > *:last-child { margin-bottom: 0; }
}