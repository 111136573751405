.wpcf7-form {
  @media(min-width: $layout-breakpoint-small) {
    border: $border-width-base solid $bg-color-light;
    transition: border-color $transition-speed;

    &.invalid {
      border-color: $negative-color;

      .form-row-submit {
        border-color: $negative-color;
        background: $negative-bg-color;
      }
      .wpcf7-response-output {
        color: $negative-color;
        background: $negative-bg-color;
      }
    }
  }

  .heading-box {
    background: $bg-color-light;
    margin-bottom: $gutter-space-base;

    @media(max-width: ($layout-breakpoint-small)-1) { padding: $gutter-space-small; }
    @media(min-width: $layout-breakpoint-small) { padding: $gutter-space-base; }

    .row {
      @media(max-width: ($layout-breakpoint-small)-1) {
        &:not(:last-child) { margin-bottom: $content-space-small; }
      }
      @media(min-width: $layout-breakpoint-small) { padding: 0; }
    }
  }

  .wpcf7-not-valid-tip { display: none; }

  hr { background: $bg-color-light; }

  .row {
    @media(min-width: $layout-breakpoint-small) {
      padding: 0 ($gutter-space-base);
    }

    &:not(:last-child) { margin-bottom: $content-space-base; }

    &:not(.aligned-row) {
      > label {
        display: inline-block;
        margin-bottom: $content-space-tiny;
      }
    }

    &.aligned-row {
      @media(max-width: ($layout-breakpoint-small)-1) {
        > label, .col > label {
          display: inline-block;
          margin-bottom: $content-space-tiny;
        }
        .col:not(:last-child) { margin-bottom: $content-space-small; }
      }
      @media(min-width: $layout-breakpoint-small) {
        display: flex;

        > label {
          flex: 0 0 auto;
          align-self: center;
          margin-right: $content-space-tiny;
        }

        .form-field-select { flex: 1 1 auto; }

        .col {
          flex: 0 1 50%;

          &:nth-child(odd)  { padding-right: $content-space-tiny; }
          &:nth-child(even) { padding-left: $content-space-tiny; }

          > label {
            display: inline-block;
            margin-bottom: $content-space-tiny;
          }

          #aanhef {
            display: block;
            border: $border-width-base solid $border-color-base;
            padding: .75em;
          }
        }
      }
    }
  }

  .row-note {
    color: $text-color-medium;
    font-size: $font-size-small;
    margin: ($content-space-tiny) 0 0;
  }

  .wpcf7-list-item:not(:last-child) { margin-right: $content-space-small; }

  .required-note { color: $negative-color; }

  .form-field-select { background: $bg-color-base; }

  .wpcf7-list-item-label { font-weight: $font-weight-bold; }

  .form-row-submit {
    margin-bottom: 0;

    @media(min-width: $layout-breakpoint-small) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: $bg-color-light;
      padding: $gutter-space-base;
      border-top: $border-width-base solid transparent;
      transition: background-color $transition-speed, border-color $transition-speed;
    }

    span {
      @media(max-width: ($layout-breakpoint-small)-1) { display: none; }
      @media(min-width: $layout-breakpoint-small) {
        flex: 0 0 auto;
        margin-right: $gutter-space-small;
      }
    }

    input[type=submit] {
      @extend .btn, .state-primary;

      @media(min-width: $layout-breakpoint-small) {
        width: auto;
        flex: 0 0 auto;
      }
    }

    .ajax-loader {
      @include spin(.9s);
      flex: 0 0 auto;
      display: block;
      margin-left: $gutter-space-small;
      border-width: rem-calc(3);
      border-style: solid;
      border-color: $text-color-base transparentize($text-color-base, .85) transparentize($text-color-base, .85);
      width: rem-calc(20);
      height: rem-calc(20);
      border-radius: 50%;

      @media(max-width: ($layout-breakpoint-small)-1) {
        margin: ($content-space-tiny) auto 0;
      }

      &:not(.is-active) { display: none; }
    }
  }

  .wpcf7-response-output {
    display: none;
    font-weight: $font-weight-bold;
    background: $bg-color-light;
    transition: background-color $transition-speed, color $transition-speed;

    @media(max-width: ($layout-breakpoint-small)-1) {
      padding: $gutter-space-small;
      margin-top: $gutter-space-small;
    }
    @media(min-width: $layout-breakpoint-small) {
      padding: 0 ($gutter-space-base) ($gutter-space-base);
      text-align: right;
    }
  }

  .wpcf7-form-control-wrap { display: block; }
}

.wpcf7 .screen-reader-response { display: none; }