.media-gallery {
  @media(max-width: ($layout-breakpoint-medium)-1) { position: relative; }

  ul {
    list-style: none;
    padding: 0; margin: 0;
  }

  .glide__bullets {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      position: absolute;
      bottom: $gutter-space-small; left: 0;
      width: 100%;

      .glide__bullet { background: $bg-color-base; }
    }
    @media(min-width: $layout-breakpoint-medium) { margin-top: $gutter-space-small; }
  }

  picture {
    position: relative;
    background: $bg-color-light;
    overflow: hidden;

    @media(max-width: 400px) { @include aspect-ratio-container(320, 150); }
    @media(min-width: 400px) and (max-width: 550px) { @include aspect-ratio-container(550, 255); }
    @media(min-width: 550px) and (max-width: 750px) { @include aspect-ratio-container(750, 350); }
    @media(min-width: 750px) and (max-width: 1400px) { @include aspect-ratio-container(1000, 454); }
    @media(min-width: 1400px) and (max-width: 1800px) { @include aspect-ratio-container(1200, 545); }
    @media(min-width: 1800px) { @include aspect-ratio-container(2000, 900); }

    img {
      display: block;
      width: 100%;
      @extend %aspect-ratio-image;
    }
  }
}

body > .media-gallery {
  @extend %section-desktop-width;

  + .content-widget .media-cont {
    @media(max-width: ($layout-breakpoint-medium)-1) { display: none; }
  }
}

main .media-gallery {
  @media(max-width: ($layout-breakpoint-medium)-1) { margin: ($gutter-space-base) 0; }
  @media(min-width: $layout-breakpoint-medium) { margin: ($gutter-space-base*1.5) 0; }
}

#about-section + .media-gallery,
#showroom-section + .media-gallery {
  @media(max-width: ($layout-breakpoint-medium)-1) { margin-top: $gutter-space-base; }
}
#showroom-section + .media-gallery {
  @media(min-width: $layout-breakpoint-medium) { margin-top: ($gutter-space-base*3); }
}