input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: $text-color-base;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}
  ::-webkit-input-placeholder { color: $text-color-light; }
  :-moz-placeholder           { color: $text-color-light; }
  ::-moz-placeholder          { color: $text-color-light; }
  :-ms-input-placeholder      { color: $text-color-light; }

input, textarea, select {
  background: transparent;
  display: block;
  width: 100%;
  color: $secondary-color;
  border: $border-width-base solid $border-color-base;
  background: $bg-color-base;
  padding: .75em;
  transition: color $transition-speed, border-color $transition-speed, background-color $transition-speed;

  &.state-invalid:invalid, &.wpcf7-not-valid {
    border-color: lighten($negative-color, 15%);
    color: $negative-color;

    &::-webkit-input-placeholder { color: $negative-color; }
    &:-moz-placeholder           { color: $negative-color; }
    &::-moz-placeholder          { color: $negative-color; }
    &:-ms-input-placeholder      { color: $negative-color; }
  }
}

input[type=checkbox], input[type=radio] {
  display: none;

  + label, + .wpcf7-list-item-label {
    display: inline-block;
    position: relative;
    padding-left: (($font-size-medium)+($content-space-tiny));

    &:before, &:after {
      display: block;
      color: transparent;
      background: $bg-color-base;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: color $transition-speed, border-color $transition-speed, background-color $transition-speed;
    }
      &:before {
        border: $border-width-base solid $border-color-base;
        width: $font-size-medium; height: $font-size-medium; line-height: $font-size-medium;
        left: 0;
      }
  }
}
  input[type=checkbox] {
    + label:before, + .wpcf7-list-item-label:before {
      @include icon-font;
      content: "\e900";
      font-size: $icon-size-base;
      text-align: center;
    }
      &:checked + label:before, &:checked + .wpcf7-list-item-label:before {
        color: $text-color-white;
        border-color: $secondary-color;
        background: $secondary-color;
      }
  }
  input[type=radio] {
    + label:before, + label:after, + .wpcf7-list-item-label:before, + .wpcf7-list-item-label:after {
      content: '';
      border-radius: 50%;
    }
      + label:after, + .wpcf7-list-item-label:after {
        left: ((($font-size-medium)-($icon-size-medium))/2);
        width: $icon-size-medium; height: $icon-size-medium; line-height: $icon-size-medium;
      }
        &:checked + label:after, &:checked + .wpcf7-list-item-label:after { background: $secondary-color; }
  }

.form-field-select {
  position: relative;

  select {
    background: transparent;
    position: relative;
    z-index: 2;
  }

  &:after {
    @include icon-font;
    content: "\e904";
    position: absolute;
    top: 50%; right: (($font-size-base*0.75)+($border-width-base));
    font-size: $icon-size-base;
    margin-top: $border-width-base;
    transform: translateY(-50%);
  }
}