.btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  font-weight: $font-weight-bold;
  color: $secondary-color;
  border: $border-width-base solid $border-color-base;
  background: $bg-color-base;
  transition: color $transition-speed, background-color $transition-speed, border-color $transition-speed;
  padding: .75em 1.25em;
  border-radius: 2em;

  &:hover {
    color: $secondary-hover-color;
    border-color: $secondary-hover-color;
    background-color: transparentize($bg-color-base, .25);
  }

  &.state-primary {
    color: $text-color-white;
    border: $border-width-base solid $primary-color;
    background: $primary-color;

    &:hover {
      color: $text-color-white;
      border-color: $primary-hover-color;
      background: $primary-hover-color;
    }
  }
}

.link-btn {
  display: inline-block;

  &:after {
    @include indicator-icon-after;
    content: "\e902";
  }
}

.btn-cont {
  @media(min-width: $layout-breakpoint-tiny) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -($content-space-small);
    margin-top: -($content-space-small);
  }

  .btn, > .link-btn {
    @media(max-width: ($layout-breakpoint-tiny)-1) {
      &:not(:last-child) { margin-bottom: $content-space-small; }
    }
    @media(min-width: $layout-breakpoint-tiny) {
      flex: 0 0 auto;
      margin-left: $content-space-small;
      margin-top: $content-space-small;
    }
  }
    .btn { display: block; }

  .link-btn-cont {
    @media(min-width: 380px) and (max-width: ($layout-breakpoint-tiny)-1) { justify-content: center; }
    @media(max-width: ($layout-breakpoint-tiny)-1) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -($content-space-small);
      margin-top: -($content-space-tiny);

      .link-btn {
        margin-left: $content-space-small;
        margin-top: $content-space-tiny;
      }
    }
    @media(min-width: $layout-breakpoint-tiny) {
      flex: 0 1 auto;
      margin-left: $content-space-base;
      margin-top: $content-space-small;

      &:first-child { margin-left: $content-space-small; }

      .link-btn { display: block; }
    }
  }
}
  aside .btn-cont .btn { flex-grow: 1; }
  .aligned-blocks .btn-cont .btn {
    @media(min-width: $layout-breakpoint-medium) and (max-width: 1050px) { flex-grow: 1; }
  }