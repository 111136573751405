.content-widget {
  @media(min-width: $layout-breakpoint-medium) {
    display: flex;
    overflow: hidden;
  }

  .block-title {
    @media(min-width: $layout-breakpoint-medium) { font-size: $heading-size-medium; }
  }

  .media-cont {
    @media(max-width: ($layout-breakpoint-medium)-1) { margin-bottom: $gutter-space-base; }
    @media(min-width: $layout-breakpoint-medium) {
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
      border-radius: $border-radius-base;
      overflow: hidden;
    }
    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-extra-large)-1) { flex-basis: 50%; }
    @media(min-width: $layout-breakpoint-extra-large) { flex-basis: 60%; }

    picture {
      position: relative;
      background: $bg-color-light;

      @media(max-width: 399px) {@include aspect-ratio-container(640, 420);}
      @media(min-width: 400px) and (max-width: 549px) { @include aspect-ratio-container(1100, 710); }
      @media(min-width: 550px) and (max-width: 740px) { @include aspect-ratio-container(1500, 970); }
      @media(min-width: 750px) and (max-width: 999px) { @include aspect-ratio-container(2000, 1290); }
      @media(min-width: 1000px) and (max-width: 1399px) { @include aspect-ratio-container(1100, 710); }
      @media(min-width: 1400px) and (max-width: 1799px) { @include aspect-ratio-container(2000, 1290); }
      @media(min-width: 1800px) { @include aspect-ratio-container(2400, 1560); }

      img {
        @extend %aspect-ratio-image;
        display: block;
        width: 100%;
      }
    }
  }

  .list-nearby-places {
    @media(max-width: ($layout-breakpoint-medium)-1) { padding: 0 ($gutter-space-small); }
    @media(min-width: $layout-breakpoint-medium) {
      flex-grow: 0;
      flex-shrink: 0;
      align-self: flex-start;
    }
    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-extra-large)-1) { flex-basis: 50%; }
    @media(min-width: $layout-breakpoint-extra-large) { flex-basis: 40%; }
  }

  .content {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      padding: 0 ($gutter-space-small);

      &:not(:last-child) { margin-bottom: $gutter-space-base; }
    }

    > *:last-child {margin-bottom: 0;}
  }

  &:not(.state-content-only) {
    @media(min-width: $layout-breakpoint-medium) { align-items: center; }

    .content {
      @media(min-width: $layout-breakpoint-medium) { flex: 0 1 auto; }
    }
  }

  &.state-content-only {
    @media(min-width: $layout-breakpoint-medium) { align-items: flex-start; }

    .content {
      @media(min-width: $layout-breakpoint-medium) {
        flex: 0 1 50%;

        &:nth-child(odd):not(:only-child) { padding-right: ($gutter-space-base); }
        &:nth-child(even) { padding-left: ($gutter-space-base); }
        &:only-child { flex: 0 0 100%; }
      }
    }
  }

  &.state-reversed {
    .list-nearby-places {
      @media(min-width: $layout-breakpoint-medium) { order: -1; }
      @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { padding-right: $gutter-space-base; }
      @media(min-width: $layout-breakpoint-large) { padding-right: ($gutter-space-base*2); }
    }
    .media-cont {
      @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { margin-right: $gutter-space-base;}
      @media(min-width: $layout-breakpoint-large) { margin-right: ($gutter-space-base*2); }
    }
  }
  &:not(.state-reversed) {
    .content {
      @media(min-width: $layout-breakpoint-medium) { order: -1; }
    }
    .list-nearby-places {
      @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { padding-left: $gutter-space-base; }
      @media(min-width: $layout-breakpoint-large) { padding-left: ($gutter-space-base*2); }
    }
    .media-cont {
      @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { margin-left: $gutter-space-base;}
      @media(min-width: $layout-breakpoint-large) { margin-left: ($gutter-space-base*2); }
    }
  }
}

body > .content-widget {
  @extend %section-desktop-width;
  @extend %section-vertical-space;

  .content h2:not([class]) { @extend .block-title; }
}

#basic-section + .content-widget, #about-section + .content-widget {
  @media(min-width: $layout-breakpoint-medium) { padding-top: 0; }
}
.block-nav.state-dark + .content-widget {
  @media(max-width: ($layout-breakpoint-medium)-1) { padding-top: 0; }
}
#content-section + .content-widget { padding-top: 0; }
#basic-section + .content-widget {
  @media(max-width: ($layout-breakpoint-medium)-1) { border-top: $border-width-base solid $border-color-light; }
}