#sub-menu {

  > li {
    padding-left: $icon-size-base+$content-space-tiny;

    &:not(:last-child) {
      margin-bottom: $content-space-medium;

      .sub-menu { margin-bottom: $content-space-small; }
    }

    > a {
      position: relative;
      display: inline-block;
      color: $text-color-dark;

      @media(min-width: $layout-breakpoint-large) { font-size: $font-size-medium; }

      &:hover { color: $primary-color; }

      &:before {
        @include indicator-icon-before;
        content: "\e902";
        position: absolute;
        color: $primary-color;
        top: 0; left: -($icon-size-base+$content-space-tiny);
        line-height: $font-size-medium*$line-height-base;
      }
    }
      &.state-current > a:before { content: "\e904"; }
  }

  .sub-menu {
    display: none;
    font-size: $font-size-small;
    margin-top: $content-space-small;

    a {
      color: $text-color-medium;

      &:hover { color: $primary-color; }
    }

    li:not(:last-child) { margin-bottom: $content-space-small; }
  }
    .state-current .sub-menu { display: block; }

}