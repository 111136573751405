#page-intro {
  @extend %section-width;
  color: $text-color-white;
  background: $bg-color-dark;

  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-top: $gutter-space-base;
    padding-bottom: $gutter-space-base;
  }
  @media(min-width: $layout-breakpoint-medium) {
    padding-top: ($gutter-space-base*2);
    padding-bottom: ($gutter-space-base*2);
  }

  %heading-base { color: $text-color-white; }

  h1 { margin-bottom: $content-space-small; }

  strong { font-weight: $font-weight-normal; }

  #breadcrumbs {
    margin-bottom: $gutter-space-small;

    p { color: $text-color-white-medium; }

    a {
      color: $text-color-white-medium;

      &:after { color: $text-color-white-medium; }

      &:hover { color: $text-color-white; }
    }
  }

  .direct-content > *:last-child { margin-bottom: 0; }

  .collapsible-content, .direct-content {
    a, .trigger-collapsible-content {
      color: $primary-color;

      &:hover { color: $primary-hover-color; }
    }
  }
}