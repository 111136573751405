#breadcrumbs {
  font-size: $font-size-small;
  overflow: hidden;

  @media(min-width: $layout-breakpoint-small) {
    display: flex;
    align-items: flex-start;
  }

  .overview-link {
    @media(max-width: ($layout-breakpoint-small)-1) { display: none; }
    @media(min-width: $layout-breakpoint-small) {
      flex: 0 0 auto;
      margin-right: $gutter-space-base;
    }

    &:before {
      @include indicator-icon-before;
      content: "\e901";
      opacity: .5;
      transition: opacity $transition-speed;
    }
      &:hover:before { opacity: 1; }
  }

  p {
    color: $text-color-medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;

    @media(min-width: $layout-breakpoint-small) { flex: 0 0 auto; }

    a {
      color: $text-color-medium;

      &:hover { color: $text-color-base; }

      &:after {
        @include indicator-icon-after;
        content: "\e902";
        color: $text-color-medium;
      }
    }

    strong { font-weight: $font-weight-normal; }
  }
}

body > #breadcrumbs {
  @extend %section-width;
  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-top: $gutter-space-base;
    padding-bottom: $gutter-space-base;
  }
  @media(min-width: $layout-breakpoint-medium) {
    padding-top: ($gutter-space-base*2);
    padding-bottom: ($gutter-space-base*2);
  }
}