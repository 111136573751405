@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
  font-family: 'helvetica_neueregular';
  src: url('../fonts/helvetica_73_bold_extended-webfont.woff2') format('woff2'), url('../fonts/helvetica_73_bold_extended-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html, body {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $text-color-base;
  padding: 0; margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  html {
    background: $primary-color;

    @media(min-width: 1600px) and (max-width: 1799px) { font-size: 18px; }
    @media(min-width: 1800px) { font-size: 20px; }
  }
  body {
    font-size: $font-size-base;
    background: $bg-color-base;
  }

%heading-base {
  font-family: $font-family-headings;
  font-weight: $font-weight-base;
  line-height: $line-height-small;
  color: $text-color-dark;
}

h1, .h1, h2, .h2, h3, .h3, .block-title {
  @extend %heading-base;
  margin-top: 0;
}
  h1, .h1 {
    margin-bottom: $content-space-medium;
    @media(max-width: 499px) { font-size: $heading-size-between; }
    @media(min-width: 500px) and (max-width: ($layout-breakpoint-medium)-1) { font-size: $heading-size-medium; }
    @media(min-width: $layout-breakpoint-medium) { font-size: $heading-size-large; }

    &.state-front {
      position: absolute;
      top: -4011px; left: -4011px;
    }
  }
  .block-title {
    @media(max-width: 499px) { font-size: $heading-size-tiny; }
    @media(min-width: 500px) { font-size: $heading-size-between; }

    .product-type { text-transform: lowercase; }
  }
  h2, .h2 {
    margin-bottom: $content-space-small;
    @media(max-width: 499px) { font-size: $heading-size-tiny; }
    @media(min-width: 500px) { font-size: $heading-size-small; }
  }
  h3, .h3 {
    margin-bottom: $content-space-small;
    @media(max-width: 499px) { font-size: $font-size-base; }
    @media(min-width: 500px) { font-size: $heading-size-tiny; }
  }

.intro {
  color: $primary-color;
  font-size: $font-size-medium;
  font-weight: $font-weight-bold;
  margin-bottom: $content-space-base;

  > *:last-child { margin-bottom: 0; }

  a {
    color: $primary-color;
    background: transparentize($primary-color, .90);
    transition: color $transition-speed, background-color $transition-speed;

    &:hover {
      color: $primary-hover-color;
      background: transparent;
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: $content-space-base;
}

a, %trigger-link {
  color: $secondary-color;
  text-decoration: none;
  transition: color $transition-speed;

  &:hover { color: $secondary-hover-color; }
}

ul, ol {
  padding-left: 1.15em;
  margin-top: 0;
  margin-bottom: $content-space-base;
}

strong { font-weight: $font-weight-bold; }

.checklist {
  list-style: none;
  padding: 0;

  li {
    position: relative;
    padding-left: (($icon-size-medium)+($content-space-small));

    &:before {
      @include icon-font;
      content: "\e900";
      position: absolute;
      top: 0; left: 0;
      color: $primary-color;
      font-size: $icon-size-medium;
      line-height: $font-size-base*$line-height-base;
    }
  }
}