$bottom-transparency-color: transparentize($text-color-white, .75);

#bottom {
  font-size: $font-size-small;
  color: $text-color-white;
  background: $primary-color;

  .heading {
    @extend %heading-base;
    font-size: $heading-size-tiny;
    color: $text-color-white;

    @media(min-width: $layout-breakpoint-medium) { margin-bottom: $content-space-base; }
  }

  a {
    color: $text-color-white;

    &:hover { color: $text-color-white-medium; }
  }

  nav {
    @extend %section-desktop-width;
    @extend %section-destop-vertical-space;

    @media(min-width: $layout-breakpoint-medium) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .nav-block, .content-block { flex: 0 1 auto; }
      .content-block:first-child { flex-basis: rem-calc(240); }
    }
  }

  .nav-block {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      border-width: ($border-width-base) 0 0;
      border-style: solid;
      border-color: $bottom-transparency-color;

      + .nav-block { border-width: ($border-width-base) 0; }

      .trigger-footer-menu {
        cursor: pointer;
        padding: $gutter-space-small;
        margin-bottom: 0;

        &:after {
          @include indicator-icon-after;
          content: "\e904";
          opacity: .5;
        }
      }

      .nav {
        display: none;
        padding: 0 ($gutter-space-small) ($gutter-space-small) ($gutter-space-small);
      }

      &.state-show-footer-nav {
        .trigger-footer-menu {
          padding-bottom: $content-space-small;

          &:after { content: "\e903"; }
        }
        .nav {
          display: block;
          @include fadeIn($transition-speed);
        }
      }
    }
  }

  .content-block {
    @media(max-width: ($layout-breakpoint-medium)-1) {
      padding: ($gutter-space-base) ($gutter-space-small);

      .heading { margin-bottom: $content-space-small; }
    }
  }
    .cta-text {
      p {
        @media(max-width: ($layout-breakpoint-medium)-1) { margin-bottom: $content-space-tiny; }
        @media(min-width: $layout-breakpoint-medium) { margin-bottom: $content-space-medium; }

        &:last-child { margin-bottom: 0; }
      }
      a {
        display: inline-block;
        font-weight: $font-weight-bold;
      }
    }

  .social-follow {
    display: flex;
    margin-bottom: $content-space-base;

    a {
      flex: 0 0 rem-calc(25);
      display: inline-block;
      font-size: $icon-size-large;
      width: rem-calc(25); height: rem-calc(25); line-height: rem-calc(25);
      text-align: center;
      background: $bottom-transparency-color;
      border-radius: 50%;
      transition: color $transition-speed, background-color $transition-speed;

      &:not(:last-child) { margin-right: $content-space-small; }

      &:hover {
        color: $primary-color;
        background: $text-color-white;
      }

      &:not(.icon-linkedin):before {
        position: relative;
        top: $border-width-base;
      }
    }
  }

  .copyright { margin-bottom: 0; }

  .pay-off {
    @extend %section-width;
    border-top: $border-width-base solid $bottom-transparency-color;
    color: $text-color-white-medium;
    margin-bottom: 0;

    @media(min-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    @media(min-width: ($layout-breakpoint-medium)-1) {
      padding-top: $gutter-space-small;
      padding-bottom: $gutter-space-small;
    }
    @media(min-width: $layout-breakpoint-medium) {
      padding-top: $gutter-space-base;
      padding-bottom: $gutter-space-base;
    }

    span {
      @media(max-width: 599px) {
        display: block;
        margin-bottom: $content-space-tiny;
      }
      @media(min-width: 600px) {
        flex: 0 1 auto;
        display: inline-block;
        margin-right: $content-space-small;
      }
    }

    a {
      display: inline-block;
      line-height: 1;
      padding: .25em;
      background: $bg-color-base;
      border-radius: $border-radius-base;

      @media(min-width: 600px) { flex: 0 0 auto; }

      &:not(:last-child) { margin-right: $content-space-tiny; }

      img {
        display: block;
        height: rem-calc(18);
      }
    }
  }
}

.media-gallery + #bottom {
  @media(min-width: $layout-breakpoint-medium) { margin-top: ($gutter-space-base*3); }
}