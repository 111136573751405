.newsletter-widget {
  .link-btn { margin-top: $content-space-medium; }
  form {
    @media (min-width: $layout-breakpoint-tiny) { position: relative; }
  }
    > label {
      display: block;
      width: 100%;
      margin-bottom: $content-space-small;
      cursor: s-resize;
    }
    .btn {
      display: block;
      width: 100%;
      margin-top: $content-space-tiny;
    }
}

@mixin positioned-newsletter-btn {
  width: auto;
  margin-top: 0;
  position: absolute;
  padding: .25em .75em;
  top: 50%; right:((((($font-size-base*0.75)*2)+($font-size-base*$line-height-base)+($border-width-base*2))-((($font-size-base*0.25)*2)+($font-size-base*$line-height-base)+($border-width-base*2)))/2);
  transform: translateY(-50%);
}
aside .newsletter-widget .btn {
  @media (min-width: $layout-breakpoint-tiny) and (max-width: ($layout-breakpoint-medium)-1) { @include positioned-newsletter-btn; }
}
.aligned-blocks .newsletter-widget .btn {
  @media (min-width: $layout-breakpoint-tiny) { @include positioned-newsletter-btn; }
}
main > .newsletter-widget .btn {
  @media (min-width: $layout-breakpoint-tiny) { @include positioned-newsletter-btn; }
}