.block-nav {

  .block-title, h1 { margin-bottom: $content-space-base; }

  .desc {
    color: $text-color-medium;
    margin-top: -(($content-space-base)-($content-space-tiny));
    margin-bottom: $content-space-base;
  }

  > p:last-child {margin-bottom: 0;}

  .nav {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) { margin-bottom: $content-space-base; }

    @media(max-width: ($layout-breakpoint-medium)-1) {
      margin-left: -($gutter-space-small);
      margin-top: -($gutter-space-small*2);
    }
    @media(min-width: $layout-breakpoint-medium) {
      margin-left: -($gutter-space-base);
      margin-top: -($gutter-space-base*2);
    }

    .el {
      flex-grow: 0;
      flex-shrink: 0;

      .inner {
        display: block;

        .inner-top {
          display: block;
          transition: color $transition-speed;
        }

        @media(max-width: ($layout-breakpoint-medium)-1) {
          margin-left: ($gutter-space-small);
          margin-top: ($gutter-space-small*2);
        }
        @media(min-width: $layout-breakpoint-medium) {
          margin-left: ($gutter-space-base);
          margin-top: ($gutter-space-base*2);
        }
      }
        a.inner, .inner-top { color: $text-color-dark; }
        a.inner:hover, .inner-top:hover {
          color: $primary-color;

          picture img { transform: scale(1.10); }
        }
    }
  }
    &#products-menu .nav .el {
      @media(max-width: ($layout-breakpoint-small)-1) { flex-basis: 50%; }
      @media(min-width: $layout-breakpoint-small) and (max-width: ($layout-breakpoint-large)-1) { flex-basis: 33.3333%; }
      @media(min-width: $layout-breakpoint-large)  { flex-basis: 25%; }
    }
    &:not(#products-menu) .nav .el {
      @media(max-width: ($layout-breakpoint-small)-1) { flex-basis: 100%; }
      @media(min-width: $layout-breakpoint-small) { flex-basis: 33.3333%; }
    }

  .title {
    line-height: $line-height-small;
    margin-bottom: 0;

    @media(min-width: $layout-breakpoint-large) { font-size: $font-size-medium; }
  }
    %heading-base.title {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }

  .post-details, .contact-details {
    font-size: $font-size-small;
    margin-top: $content-space-medium;
    margin-bottom: 0;
  }

  .post-details { color: $text-color-medium; }

  .contact-details {
    .page-link { display: block; }

    [class^='icon-']:before, [class*=' icon-']:before { line-height: ($font-size-small*$line-height-base); }
  }

  picture {
    position: relative;
    background: $bg-color-light;
    overflow: hidden;
    margin-bottom: $content-space-medium;

    img {
      @extend %aspect-ratio-image;
      display: block;
      width: 100%;
      transition: transform ($transition-speed*3);
    }
  }
    &#products-menu picture {
      @media(max-width: 399px) { @include aspect-ratio-container(370, 470); }
      @media(min-width: 400px) and (max-width: 749px)   { @include aspect-ratio-container(720, 910); }
      @media(min-width: 750px) and (max-width: 999px)  { @include aspect-ratio-container(640, 820); }
      @media(min-width: 1000px) and (max-width: 1399px) { @include aspect-ratio-container(500, 630); }
      @media(min-width: 1400px) and (max-width: 1799px) { @include aspect-ratio-container(760, 960); }
      @media(min-width: 1800px) { @include aspect-ratio-container(880, 1110); }
    }
    &:not(#products-menu) picture { @include aspect-ratio-container(1400, 1400); }

  .btn-cont {
    margin-bottom: 0;

    @media(min-width: $layout-breakpoint-medium) { justify-content: center; }
  }

}

#breadcrumbs, .aligned-blocks, .content-widget {
  & + .block-nav:not(.state-dark) { padding-top: 0; }
}
.block-nav:not(.state-dark) + .content-widget { padding-top: 0; }

#overview-section .block-nav {
  @media(max-width: ($layout-breakpoint-medium)-1) { padding: ($gutter-space-base) ($gutter-space-small); }
}

body > .block-nav {
  @extend %section-base;

  &.state-dark {
    background: $bg-color-dark;

    %heading-base { color: $text-color-white; }

    .nav .el {
      a.inner, .inner-top {
        color: $text-color-white;

        &:hover { color: $primary-color; }
      }
    }

    .desc, .post-details { color: $text-color-white-medium; }

    .contact-details a {
      color: $text-color-white-medium;

      &:hover { color: $text-color-white; }
      &.page-link { font-weight: $font-weight-bold; }
    }

    picture { background-color: $bg-color-base; }

    .btn:not(.state-primary) {
      background: transparent;
      color: $text-color-white-medium;
      border-color: $text-color-white-medium;

      &:hover {
        background: transparent;
        color: $text-color-white;
        border-color: $text-color-white;
      }
    }
  }

  &#products-menu .nav .el {
    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) { flex-basis: 25%; }
    @media(min-width: $layout-breakpoint-large) { flex-basis: 20%; }
  }

  &.state-top-level {
    @media(min-width: $layout-breakpoint-medium) {
      .block-title, .desc { text-align: center; }
    }
    &#products-menu .nav .el {
      @media(min-width: $layout-breakpoint-medium) { flex-basis: 20%; }
    }
  }
}