*, *::before, *::after { box-sizing: border-box; }

section, nav, main, aside, picture { display: block; }

%section-width {
  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-left: $gutter-space-small;
    padding-right: $gutter-space-small;
  }
  @extend %section-desktop-width;
}
  %section-desktop-width {
    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) {
      padding-left: $gutter-space-base;
      padding-right: $gutter-space-base;
    }
    @media(min-width: $layout-breakpoint-large) {
      padding-left: 8vw;
      padding-right: 8vw;
    }
  }
%section-vertical-space {
  @media(max-width: ($layout-breakpoint-medium)-1) {
    padding-top: $gutter-space-base;
    padding-bottom: $gutter-space-base;
  }
  @extend %section-destop-vertical-space;
}
  %section-destop-vertical-space {
    @extend %section-destop-vertical-space-bottom;
    @media(min-width: $layout-breakpoint-medium) { padding-top: ($gutter-space-base*3); }
  }
  %section-destop-vertical-space-bottom {
    @media(min-width: $layout-breakpoint-medium) { padding-bottom: ($gutter-space-base*3); }
  }
%section-base {
  @extend %section-width;
  @extend %section-vertical-space;
}

ul.nav, .nav ul {
  list-style: none;
  padding: 0; margin: 0;
}

hr {
  border: none;
  height: $border-width-base;
  background: $border-color-base;
  margin-top: ($content-space-base);
  margin-bottom: ($content-space-base);

  &.section-divider {
    margin-top: 0;
    margin-bottom: 0;

    @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-large)-1) {
      margin-left: $gutter-space-base;
      margin-right: $gutter-space-base;
    }
    @media(min-width: $layout-breakpoint-large) {
      margin-left: 8vw;
      margin-right: 8vw;
    }
  }
}

#about-section {
  @extend %section-desktop-width;
  @extend %section-destop-vertical-space-bottom;

  h1 {
    @media(max-width: $layout-breakpoint-medium) {
      padding-left: $gutter-space-small;
      padding-right: $gutter-space-small;
    }
  }
}

#content-section {
  @extend %section-desktop-width;

  @media(max-width: ($layout-breakpoint-medium)-1) { padding-bottom: $gutter-space-base; }
  @media(min-width: $layout-breakpoint-medium) { padding-bottom: ($gutter-space-base*3); }

  .block-title {
    margin-bottom: $gutter-space-base;

    @media(max-width: $layout-breakpoint-medium) {
      padding-left: $gutter-space-small;
      padding-right: $gutter-space-small;
    }
  }
}
  .media-gallery + #content-section {
    @media(max-width: ($layout-breakpoint-medium)-1) { padding-top: $gutter-space-base; }
    @media(min-width: $layout-breakpoint-medium) { padding-top: ($gutter-space-base*3); }
  }

#showroom-section {
  @extend %section-width;

  @media(min-width: $layout-breakpoint-small) { display: flex; }
  @media(min-width: $layout-breakpoint-small) and (max-width: $layout-breakpoint-large) { flex-wrap: wrap; }

  h1 {
    @media(min-width: $layout-breakpoint-small) {
      flex-grow: 0;
      flex-shrink: 0;
    }
    @media(min-width: $layout-breakpoint-small) and (max-width: ($layout-breakpoint-large)-1) { flex-basis: 100%; }
    @media(min-width: $layout-breakpoint-large) {
      flex-basis: 33.3333%;
      margin-bottom: 0;
    }
  }

  .block {
    @media(max-width: ($layout-breakpoint-small)-1) {
      &:not(:last-child) { margin-bottom: $content-space-base; }
    }
    @media(min-width: $layout-breakpoint-small) {
      flex-grow: 0;
      flex-shrink: 0;
    }
    @media(min-width: $layout-breakpoint-small) and (max-width: ($layout-breakpoint-large)-1) { flex-basis: 50%; }
    @media(min-width: $layout-breakpoint-large) { flex-basis: 33.3333%; }
  }
    h1 + .block {
      @media(min-width: $layout-breakpoint-large) and (max-width: ($layout-breakpoint-extra-large)-1) {
        padding-left: $gutter-space-base;
        padding-right: $gutter-space-base;
      }
      @media(min-width: $layout-breakpoint-extra-large) {
        padding-left: ($gutter-space-base*2);
        padding-right: ($gutter-space-base*2);
      }
    }

  .contact-details {
    margin-bottom: $content-space-small;

    &:last-child { margin-bottom: 0; }
  }

  .btn {
    @media(max-width: $layout-breakpoint-tiny) { display: block; }
  }
}

#basic-section, #overview-section {
  @extend %section-desktop-width;
  @extend %section-destop-vertical-space;

  @media(min-width: $layout-breakpoint-medium) {
    display: flex;
    align-items: flex-start;
  }

  main {
    @media(min-width: $layout-breakpoint-medium) {
      flex: 0 1 auto;
      min-width: 0;
    }
  }
}
  #basic-section {
    overflow: hidden;

    @media(max-width: ($layout-breakpoint-medium)-1) {
      &:not(.state-clear) { padding-bottom: $gutter-space-base; }
    }

    main {
      @media(max-width: ($layout-breakpoint-medium)-1) {
        padding: 0 ($gutter-space-small);

        &:not(:only-child) { margin-bottom: $gutter-space-base; }
      }

      > *:last-child { margin-bottom: 0; }

      img {
        display: block;
        max-width: 100%;
      }
    }

    aside {
      @media(min-width: $layout-breakpoint-medium) {
        flex-grow: 0;
        flex-shrink: 0;
        min-width: 0;
        margin-left: ($gutter-space-small*3);
      }
      @media(min-width: $layout-breakpoint-medium) and (max-width: ($layout-breakpoint-extra-large)-1) { flex-basis: 30%; }
      @media(min-width: $layout-breakpoint-extra-large) { flex-basis: 27.5%; }

      > *:not(:last-child) { margin-bottom: $gutter-space-base; }
    }
  }
    #breadcrumbs + #basic-section { padding-top: 0; }
    #page-intro + #basic-section {
      @media(max-width: ($layout-breakpoint-medium)-1) { padding-top: $gutter-space-base; }
    }
  #overview-section {
    main {
      @media(min-width: $layout-breakpoint-medium) { flex-grow: 1; }
    }

    > nav {
      @media(max-width: ($layout-breakpoint-medium)-1) { display: none; }
      @media(min-width: $layout-breakpoint-medium) {
        flex: 0 0 auto;
        min-width: 0;
        order: -1;
        margin-right: ($gutter-space-small*3);
      }
    }

    .overview-content {
      border-top: $border-width-base solid $bg-color-light;

      @media(max-width: ($layout-breakpoint-medium)-1) { padding: ($gutter-space-base) ($gutter-space-small); }
      @media(min-width: $layout-breakpoint-medium) {
        margin-top: ($gutter-space-base*3);
        padding-top: ($gutter-space-base*3);
      }

      > *:last-child { margin-bottom: 0; }
    }
  }

.youtube-video {
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    padding-top: ((9 / 16) * 100%);
    width: 100%;
  }

  iframe {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    display: block;
  }
}