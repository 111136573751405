.list-nearby-places {

  .nav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -($content-space-base);
    margin-top: -($content-space-tiny);

    li {
      flex-grow: 0;
      flex-shrink: 0;

      @media(max-width: 399px) { flex-basis: 50%; }
      @media(min-width: 400px) and (max-width: ($layout-breakpoint-tiny)-1) { flex-basis: 33.3333%; }
      @media(min-width: $layout-breakpoint-tiny) { flex-basis: auto; }
    }
  }

  a {
    display: inline-block;
    position: relative;
    padding-left: 1.5em;
    color: $text-color-base;
    margin-left: $content-space-base;
    margin-top: $content-space-tiny;

    &:hover { color: $primary-color; }

    &:before {
      @include icon-font;
      content: "\e90a";
      font-size: 1.25em;
      position: absolute;
      top: 50%; left: 0;
      color: $primary-color;
      transform: translateY(-50%);
    }
  }
}